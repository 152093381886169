import axios from 'axios';

// eslint-disable-next-line
export const requestApi = async (
  params: any | FormData, method: string, endpoint: string,
): Promise<any> => {
  let headers: any = { 'Content-Type': 'application/json' };
  if (params instanceof FormData) {
    headers = {
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest',
    };
  }

  if (method === 'delete') {
    // deleteだとaxiosのリクエスト方法が変わるため条件分岐で対応
    return axios[method](endpoint, { data: params });
  }
  return axios[method](endpoint, params, { headers });
};
