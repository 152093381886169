import React from 'react';
import { Block } from '../../types/editorjs';
import { requestApi } from '../../shared/requests';
import Header from '@editorjs/header';
import Image from '@editorjs/image';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import LinkTool from '@editorjs/link';
import Table from '@editorjs/table';
import EditorJs from 'react-editor-js';
import * as $ from 'jquery';

export interface Props {
  editorInstance: any;
  setEditorInstance: any;
  blockData: Block[];
  setBlockData: any;
  setBody: any;
}

export const Editor: React.FC<Props> = ({
  blockData, setBlockData, setEditorInstance, editorInstance, setBody,
}) => {
  const uploadByFile = async (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('common_image[image]', file);

    try {
      const res = await requestApi(formData, 'post', `/api/common_images`);
      return { success: 1, file: { url: res.data.image } };
    } catch (e) {
      $(document).ready(() => {
        document.getElementsByClassName('cdx-notify')[0].remove();
      });
      // Image Tool: uploading failed because of incorrect response: {"success":0}
      // document通りに実装してもエラー出る
      return { success: 1 };
    }
  }

  const editorInstanceToObject = (): void => {
    if (editorInstance === null) {
      return;
    }
    try {
      editorInstance.save().then((res) => {
        for (let i = 0; res.blocks.length > i; i += 1) {
          if (res.blocks[i].type === 'image' && res.blocks[i].data.file !== {}) {
            // NOTE(okubo): なぜかamp; が入ってしまうので原因を解決する
            res.blocks[i].data.file.url = res.blocks[i].data.file.url.replace('amp;', '');
          }
        }
        setBlockData(res.blocks);
        setBody(JSON.stringify(res.blocks));
      });
    } catch (e) {
      console.log(e);
    }
  };

  const config: any = {
    paragraph: {
      class: Paragraph,
      inlineToolbar: ['link', 'bold', 'italic'],
      config: {
        placeholder: '内容',
      },
    },
    image: {
      class: Image,
      config: {
        // 画像を選択している際の選択肢及びcaptionはcssでdisableにしている。
        uploader: { uploadByFile },
      },
    },
    header: {
      class: Header,
      config: {
        defaultLevel: 3,
        placeholder: '見出し',
      },
    },
    list: {
      class: List,
      inlineToolbar: true,
    },
    table: {
      class: Table,
      inlineToolbar: true,
      config: {
        rows: 2,
        cols: 3,
      },
    },
    linkTool: {
      class: LinkTool,
      config: {
        endpoint: '/api/editorjs_links', // Your backend endpoint for url data fetching,
      }
    }

  };

  const i18n = {
    messages: {
      ui: {
        blockTunes: {
          toggler: {
            'Click to tune': '操作',
            'or drag to move': 'или перетащите',
          },
        },
        inlineToolbar: {
          converter: {
            'Convert to': '変換',
          },
        },
        toolbar: {
          toolbox: {
            Add: '追加',
          },
        },
      },
      toolNames: {
        Text: '内容',
        Heading: '見出し',
        Link: 'リンク',
        Bold: '太文字',
        Italic: 'イタリック',
        Image: '画像',
      },
      tools: {
        link: {
          'Add a link': 'https://ingrebank.com',
        },
        image: {
          'Select an Image': '画像ファイル(.jpg、.jpeg、.png)を選択',
        },
        stub: {
          'The block can not be displayed correctly.': 'Блок не может быть отображен',
        },
        list: {
          "Ordered": "番号付き",
          "Unordered": "番号なし"
        }
      },
      blockTunes: {
        delete: {
          Delete: '削除',
        },
        moveUp: {
          'Move up': '上に移動',
        },
        moveDown: {
          'Move down': '下に移動',
        },
      },
    },
  };

  return (
    <EditorJs
      instanceRef={(instance) => setEditorInstance(instance)}
      tools={config}
      defaultBlock="header"
      i18n={i18n}
      onChange={editorInstanceToObject}
      data={{ blocks: blockData }}
    />
  );
};
