require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('cocoon');

import '../css/tailwind.css';

require('./toggle_button');
require('./data_link');

require.context('../images', true)
require('./blog_form');
require('./all_ingredients_displays_form');

import './flash';
