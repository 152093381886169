import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Editor } from '../components/organisms/Editor';
import { Block } from '../types/editorjs';
import * as $ from 'jquery';

export interface Props {
  defaultBody: string;
  target: string;
}

/* Form生成関数 */
const App: React.FC<Props> = ({ defaultBody, target }) => {
  const [editorInstance, setEditorInstance] = useState<any>();
  const [blockData, setBlockData] = useState<Block[]>(defaultBody ? JSON.parse(defaultBody) : []);
  const [body, setBody] = useState<string>(JSON.stringify(blockData));

  return (
    <>
      <input name={ `${target}` } value={body} type="hidden" />
      <Editor
        editorInstance={editorInstance}
        setEditorInstance={setEditorInstance}
        blockData={blockData}
        setBlockData={setBlockData}
        setBody={setBody}
      />
    </>
  );
};

// NOTE: ActiveAdminはturbolinksとは関係ないかと思うので、window object制御で
const app = () => {
  const elm = document.querySelector('#jslnIngredientBlogForm');
  if (!elm) {
    return;
  }

  const body = JSON.parse(elm.getAttribute('body'));
  const target = elm.getAttribute('target');

  ReactDOM.render(
    <App defaultBody={body} target={target} />,
    elm,
  );
}

// NOTE: 画面遷移した時用
document.addEventListener("turbolinks:load", () => {
  app();
});

// NOTE: リフレッシュした時用
$(document).ready(() => {
  app();
});

