/* tableのtrをクリック -> click event発火 */
import * as $ from 'jquery';

document.addEventListener('turbolinks:load', () => {
  $(function () {
    $('tr[data-link]').on('click', function () {
      window.location = $(this).data('link');
    });
  });
});
