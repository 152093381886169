$(document).on('cocoon:after-insert', function () {
  const radios: JQuery = $("input:radio[name='product[is_main]']");
  console.log(radios.length);
  for (let i: number = 0; i < radios.length; i++) {
    const newId: string = radios[i].id
      .replace('product_jsln_ingredients_attributes_', '')
      .replace('_is_main_new_jsln_ingredients', '')
      .replace('_is_main_0', '');
    (radios[i] as HTMLInputElement).value = newId;
  }
});
